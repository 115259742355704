import data from "dealershipDashboard.json";

import { IAuth } from "types";

type CustomDealershipDashboard = {
  id: string;
  name: string;
  url: string;
};

export const getDashboardUrl = (user: IAuth | null | undefined) => {
  try {
    const dealerships = data as { items: CustomDealershipDashboard[] };

    return dealerships.items.find((e) => e.id === user?.data.dealership?.id)
      ?.url;
  } catch (error) {
    console.warn("Error loading dealership report data.");
  }
};
